import { useLayoutEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'

const LeafletRoutingMachine = (props) => {
    let routing= null;
    const [position, setPosition] = useState({
        lat: props.lat,
        lng: props.lng
    });

    const map = useMap();

    useLayoutEffect(() => {
        map.on('locationfound', function (e) {
            setPosition(e.latlng)
        });

        map.on('click', function (e) {
            routing?.remove();

            routing = L.Routing.control({
                waypoints: [
                    L.latLng(position?.lat, position?.lng),
                    L.latLng(e.latlng.lat, e.latlng.lng)
                ],
                lineOptions: {
                    styles: [
                        {
                            color: "blue",
                            weight: 4,
                            opacity: 0.7
                        }
                    ],
                    extendToWaypoints: false,
                    missingRouteTolerance: 100
                },
            }).addTo(map);
        })
    }, [])

    return null;
}

export default LeafletRoutingMachine