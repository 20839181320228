import { Component, lazy, Suspense } from 'react';
import { NavLink, Link, Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.css';

import Home from './pages/Home';
import Loading from './pages/components/Loading';

const Articles = lazy(() => import('./pages/Articles'));
const Article = lazy(() => import('./pages/Article'));
const House = lazy(() => import('./pages/House'));

class App extends Component {
    render() {
        return (
            <>
                <header className="container navbar">
                    <div className="container_body">
                        <Link to="/" className="logo">
                            <img alt="Logo" src="/images/logo.svg"/>
                        </Link>
                        <nav>
                            <NavLink to="/">Home</NavLink>
                            <NavLink to="/articles">Articles</NavLink>
                            <NavLink to="/house"><span className="icon-home3"></span></NavLink>
                        </nav>
                    </div>
                </header>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route exact path="/" element={<Home />}/>
                        <Route exact path="/articles" element={<Articles />}/>
                        <Route exact path="/articles/:id" element={<Article />}/>
                        <Route exact path="/house" element={<House />}/>
                    </Routes>
                </Suspense>
                <footer className="container container_theme_grey">
                    <div className="container_body">
                        <nav>
                            {
                                this.props.links.map((link, index) =>
                                    <a key={index} href={link.href}>{link.name}</a>
                                )
                            }
                        </nav>
                        <p className="copyright">BDerevyaga © 2024</p>
                    </div>
                </footer>
            </>
        );
    }
}

export default connect(
    state => ({
        links: state.links
    })
)(App);
