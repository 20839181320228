import OSMMap from './components/OSMMap';

const Home = () => {
    document.title = 'Bogdan Derevyaga | Home';

    return (
        <>
            <header className="container container_theme_dark">
                <div className="container_body">
                    <h1>Hello I'm Bogdan Derevyaga</h1>
                    <p>I'm a passionate Full Stack Developer, and on this website, I'll share my work and my deep interests in the programming field.</p>
                </div>
            </header>
            <OSMMap />
        </>
    );
};

export default Home;