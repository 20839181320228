import ADD_LINK from '../actions/add_link';

const defaultState = [
    {
        href: 'https://www.linkedin.com/in/bderevyaga',
        name: 'LinkedIn'
    },
    {
        href: 'https://www.facebook.com/bderevyaga',
        name: 'Facebook'
    },
    {
        href: 'https://twitter.com/bderevyaga',
        name: 'Twitter'
    },
    {
        href: 'https://github.com/bderevyaga',
        name: 'GitHub'
    }
];

export default function links(state = defaultState, action) {
    switch (action.type) {
        case ADD_LINK:
            return [
                ...state,
                action.value
            ];
        default:
            return state;
    }
}