import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import useGeoLocation from './useGeoLocation';
import LeafletRoutingMachine from './LeafletRoutingMachine';
import Locate from './Locate';
import LeafletGeocoder from './LeafletGeocoder';

function OSMMap() {
    const location = useGeoLocation();

    if (location.loaded) {
        return (
                <MapContainer center={[location.coordinates.lat, location.coordinates.lng]} zoom={15} style={{ minHeight: '300px', flex: '1 1 auto', width: '100%' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[location.coordinates.lat, location.coordinates.lng]}>
                        <Popup>A sample marker on OpenStreetMap.</Popup>
                    </Marker>
                    <Locate />
                    <LeafletGeocoder />
                    <LeafletRoutingMachine lat={location.coordinates.lat} lng={location.coordinates.lng} />
                </MapContainer>
        );
    }
}

export default OSMMap;
