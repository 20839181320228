import { useMap } from 'react-leaflet';
import L from 'leaflet'
import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
import { useEffect } from 'react';

const Locate = () => {
    const map = useMap();

    useEffect(() => {
        L.control.locate({
            position: "topleft",
            strings: {
                title: "Show me where I am, yo!"
            }
        }).addTo(map);
    }, []);

    return null
}

export default Locate