import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import ReactGA from 'react-ga4';

import App from './App';
import store from './store/store';

if (process.env.REACT_APP_GA_TRACKING_CODE) {
    setTimeout(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
    }, 1000);
}

const client = new ApolloClient({
    uri: `${process.env.REACT_APP_SERVER}/graphql`,
    cache: new InMemoryCache()
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </ApolloProvider>
);
