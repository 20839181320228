import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from './reducers/rootReducer';

const store = configureStore({
    reducer,
    middleware: composeWithDevTools()
});

export default store;