import './Loading.css';

const Loading = () => {
    return (
        <>
            <header className="container container_theme_dark loading">
                <div className="container_body"><h1/><p/></div>
            </header>
            <section className="container loading">
                <div className="container_body">
                    <div className="articles_link"><h3/><p/></div>
                    <div className="articles_link"><h3/><p/></div>
                    <div className="articles_link"><h3/><p/></div>
                </div>
            </section>
        </>
    );
};

export default Loading;
