import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet'
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';

const LeafletGeocoder = () => {
    let marker= null;
    const map = useMap();

    useEffect(() => {
        L.Control.geocoder({
            defaultMarkGeocode: false
        }).on('markgeocode', function (e) {
            marker?.remove();
            marker = L
                .marker(e.geocode.center)
                .addTo(map)
                .bindPopup(e.geocode.name)
                .openPopup();
        }).addTo(map);
    }, [])

    return null;
}

export default LeafletGeocoder